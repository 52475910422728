<template>
  <div class="text-center mt-2" v-if="!dataLoaded">
    <v-progress-circular :size="50" color="primary" indeterminate />
  </div>

  <div v-else>
    <ScheduleCreate
      v-if="!isFact && !scheduleLines.length && !isAnyChanges.plan"
      @generateSchedule="generateSchedule"
      @createNewSchedule="createNewSchedule"
    />

    <ScheduleInfo
      v-if="scheduleLines.length || isAnyChanges.plan || isAnyChanges.fact"
      :isFact="isFact"
      :hasFact="hasFact"
      :hasPlanSchedule="hasPlanSchedule"
      :hasFactSchedule="hasFactSchedule"
      :isAllowSaveFactSchedule="isAllowSaveFactSchedule"
      :isAnyChanges="isAnyChanges"
      :widgetErrors="widgetErrors"
      :saveScheduleLoading="saveScheduleLoading"
      :widget="calcWidget"
      @createFactSchedule="createFactSchedule"
      @setTypeTab="isFact = $event"
      @setScheduleInfoHeight="scheduleInfoHeight = $event"
      @sendScheduleLines="sendScheduleLines"
      @removeScheduleLines="confirmDialog = true"
    />

    <ScheduleTable
      v-if="scheduleLines.length"
      :isFact="isFact"
      :hasFact="hasFact"
      :hasFactSchedule="hasFactSchedule"
      :scheduleInfoHeight="scheduleInfoHeight"
      :infoBlockHeight="infoBlockHeight"
      :lines="scheduleLines"
      :cycleHolidays="schedule.holidays"
      @addLine="addLine"
      @editLine="editLine"
      @copyLine="copyLine"
      @deleteLine="deleteLine"
    />

    <DeleteConfirmDialog
      :dialog="confirmDialog"
      :loading="removeScheduleLoading"
      :message="`Вы уверены, что хотите удалить ${ isFact ? 'фактическое' : 'плановое' } расписание?`"
      @confirm="removeScheduleLines"
      @closeConfirmModal="closeConfirmDialog"
    />
  </div>
</template>

<script>
import { scheduleApi, holidayApi, cycleApi } from '@/api'
import { mapGetters } from 'vuex'
import { Schedule, ScheduleWidget } from '@/models'
import ScheduleCreate from '@/components/cycles/cycle/schedule/Create'
import ScheduleInfo from '@/components/cycles/cycle/schedule/Info'
import ScheduleTable from '@/components/cycles/cycle/schedule/Table'
import DeleteConfirmDialog from '@/components/dialogs/DeleteConfirm'

export default {
  name: 'Schedule',

  metaInfo: {
    title: 'Расписание'
  },

  components: {
    ScheduleCreate,
    ScheduleInfo,
    ScheduleTable,
    DeleteConfirmDialog
  },

  props: {
    infoBlockHeight: Number
  },

  async created() {
    await this.getScheduleLines()
    await this.getHolidays()
    await this.getUndistributedHoursPlan()
    await this.getUndistributedHoursFact()
    this.dataLoaded = true
  },

  data: () => ({
    dataLoaded: false,
    isFact: false,
    isAnyChanges: {
      plan: false,
      fact: false
    },
    confirmDialog: false,
    saveScheduleLoading: false,
    removeScheduleLoading: false,
    scheduleInfoHeight: 0,

    schedule: new Schedule(),
    widget: new ScheduleWidget()
  }),

  computed: {
    ...mapGetters('cycle', ['cycleId', 'fromDate', 'toDate']),

    hasFact() {
      return !!this.schedule.factSchedule.length
    },

    hasPlanSchedule() {
      const planSchedule = this.schedule.planSchedule
      return !!(planSchedule.length && planSchedule.every(el => el.academicWork))
    },

    hasFactSchedule() {
      const factSchedule = this.schedule.factSchedule

      return !!(factSchedule.length && factSchedule.every(el => {
        return el.scheduleId && el.groups.length && (el.academicWork?.id === 11 || el.scheduleLects.length)
      }))
    },

    isAllowSaveFactSchedule() {
      const factSchedule = this.schedule.factSchedule

      // ToDo: временно для прода
      if ([67938, 68001].includes(this.cycleId)) {
        return true
      }

      return !!(factSchedule.length && factSchedule.every(el => {
        return el.groups.length && (el.academicWork?.id === 11 || el.scheduleLects.length)
      }))
    },

    scheduleLines() {
      return this.isFact ? this.schedule.factSchedule : this.schedule.planSchedule
    },

    calcWidget() {
      return this.widget.getCalcWidget(this.schedule.planSchedule, this.schedule.factSchedule)
    },

    widgetErrors() {
      const planErrors = this.calcWidget.plan.academicWorks.some(el => !el.isCorrect)
      const factErrors = this.calcWidget.fact.academicWorks.some(el => !el.isCorrect)

      return {
        plan: planErrors,
        fact: factErrors
      }
    }
  },

  mounted() {
    this.hideHtmlOverflow()
  },

  destroyed() {
    this.showHtmlOverflow()
  },

  methods: {
    async getScheduleLines() {
      try {
        const planSchedule = await scheduleApi.getPlanSchedule(this.cycleId)
        const factSchedule = await scheduleApi.getFactSchedule(this.cycleId)

        planSchedule.length && this.schedule.buildFromAPI(planSchedule)
        factSchedule.length && this.schedule.buildFromAPI(factSchedule)
      } catch (e) {
        console.log(e.status)
      }
    },

    async sendScheduleLines() {
      try {
        let body, data
        this.saveScheduleLoading = true

        if (this.isFact) {
          body = this.schedule.getFactForAPI()
          data = await scheduleApi.sendFactSchedule(this.cycleId, body)
          await cycleApi.addThemeList(this.cycleId)
          data?.length ? this.schedule.buildFromAPI(data) : this.isFact = false

          await this.$store.dispatch('cycle/setCycle', this.cycleId)

          this.isAnyChanges.fact = false
        } else {
          body = this.schedule.getPlanForAPI()
          data = await scheduleApi.sendPlanSchedule(this.cycleId, body)

          if (data?.length) {
            this.schedule.buildFromAPI(data)
          }

          if (!this.calcWidget.plan.isCorrect) {
            this.$toastr('warning', '', 'Плановое количество часов и количество распределенных часов не совпадает')
          } else if (this.widgetErrors.plan) {
            this.$toastr('warning', '', 'Для выделенной строки разность между плановыми и распределенными часами больше 15%')
          }

          this.isAnyChanges.plan = false
        }

        this.$toastr('success', '', 'Расписание сохранено')
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка сохранения расписания')
      } finally {
        this.saveScheduleLoading = false
      }
    },

    async removeScheduleLines() {
      try {
        this.removeScheduleLoading = true

        if (this.isFact) {
          await scheduleApi.removeFactSchedule(this.cycleId)
          this.schedule.removeFactSchedule()
          this.isAnyChanges.fact = false
          this.isFact = false
          this.closeConfirmDialog()
        } else {
          await scheduleApi.removePlanSchedule(this.cycleId)
          this.schedule.removePlanSchedule()
          this.isAnyChanges.plan = false
          // обновляем информацию о наличии расписания для цикла в сторе
          await this.$store.dispatch('cycle/setIsSchedule', false)
          this.closeConfirmDialog()
        }
        this.$toastr('success', '', 'Расписание удалено')
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка удаления расписания')
      } finally {
        this.removeScheduleLoading = false
      }
    },

    async getHolidays() {
      try {
        const data = await holidayApi.getCycleHolidays(this.cycleId)
        data.length && this.schedule.setHolidays(data)
      } catch (e) {
        console.log(e.status)
      }
    },

    async getUndistributedHoursPlan() {
      try {
        const data = await scheduleApi.getUndistributedHoursPlan(this.cycleId)
        data.length && this.widget.setAcademicWorksHours(data)
      } catch (e) {
        console.log(e.status)
      }
    },

    async getUndistributedHoursFact() {
      try {
        const data = await scheduleApi.getUndistributedHoursFact(this.cycleId)
        data.length && this.widget.setAcademicWorksHours(data)
      } catch (e) {
        console.log(e.status)
      }
    },

    generateSchedule(lines) {
      lines.length && this.schedule.buildFromAPI(lines)
      this.isAnyChanges.plan = true
    },

    createNewSchedule(params) {
      this.schedule.createNewSchedule(this.fromDate, this.toDate, params)
      this.isAnyChanges.plan = true
    },

    createFactSchedule() {
      this.schedule.createFactSchedule()
      this.isFact = true
      this.isAnyChanges.fact = true
    },

    addLine(line) {
      this.schedule.addScheduleLine(line)
      line.isFact ? this.isAnyChanges.fact = true : this.isAnyChanges.plan = true
    },

    editLine(params) {
      this.schedule.editScheduleLine(params)
      params.isFact ? this.isAnyChanges.fact = true : this.isAnyChanges.plan = true
    },

    copyLine(params) {
      this.schedule.copyScheduleLine(params)
      params.isFact ? this.isAnyChanges.fact = true : this.isAnyChanges.plan = true
    },

    deleteLine(params) {
      this.schedule.deleteScheduleLine(params)
      params.isFact ? this.isAnyChanges.fact = true : this.isAnyChanges.plan = true
    },

    closeConfirmDialog() {
      this.confirmDialog = false
    },

    hideHtmlOverflow() {
      document.querySelector('html').style.setProperty('overflow-y', 'hidden', 'important')
    },

    showHtmlOverflow() {
      document.querySelector('html').style.overflowY = 'visible'
    }
  }
}
</script>