<template>
  <div>
    <v-row class="my-0">
      <v-col cols="12" class="py-0">
        <v-card class="base-card pa-5">
          <div class="schedule-info accent--text">
            <p><strong>Кол-во групп (план Аксапта): </strong>{{ cycleInfo.groupCnt }}</p>
            <p><strong>Кол-во групп (факт): </strong>{{ cycleInfo.groupCntFact }}</p>
          </div>

          <div class="d-flex mt-5">
            <v-btn
              :loading="templatesLoading"
              small
              color="primary"
              class="white--text px-4 mr-3"
              @click="getTemplatesParams"
            >
              <v-icon left>mdi-clipboard-text-outline</v-icon>Создать расписание из шаблона
            </v-btn>

            <v-btn
              :loading="createLoading"
              small
              color="primary"
              class="white--text px-4 mr-5"
              @click="getCreateParams"
            >
              <v-icon left>mdi-plus</v-icon>Создать новое расписание
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <ScheduleTemplatesDialog
      :dialog="scheduleTemplatesDialog"
      :templates="templates"
      :generateParams="generateParams"
      @generateSchedule="$emit('generateSchedule', $event)"
      @closeDialog="closeScheduleTemplatesDialog"
    />

    <ScheduleCreateDialog
      :dialog="scheduleCreateDialog"
      :createParams="createParams"
      @createNewSchedule="$emit('createNewSchedule', $event)"
      @closeDialog="closeScheduleCreateDialog"
    />
  </div>
</template>

<script>
import { scheduleApi } from '@/api'
import { mapGetters } from 'vuex'
import ScheduleTemplatesDialog from '@/components/cycles/cycle/schedule/dialogs/ScheduleTemplates'
import ScheduleCreateDialog from '@/components/cycles/cycle/schedule/dialogs/ScheduleCreate'

export default {
  name: 'ScheduleCreate',

  components: {
    ScheduleTemplatesDialog,
    ScheduleCreateDialog
  },

  data: () => ({
    dataLoaded: false,
    scheduleTemplatesDialog: false,
    scheduleCreateDialog: false,
    templatesLoading: false,
    createLoading: false,
    templates: [],

    generateParams: {
      lessonsBegin: [],
      breaksDuration: [],
      bigbreaksDuration: [],
      bigbreaksBegin: []
    },

    createParams: {
      lessonsBegin: []
    }
  }),

  computed: {
    ...mapGetters('department', ['departmentId']),
    ...mapGetters('cycle', ['cycleInfo'])
  },

  methods: {
    sortByName(a, b) {
      const A = a.description.toLowerCase(), B = b.description.toLowerCase()
      return A < B ? -1 : (A > B ? 1 : 0)
    },

    async getTemplatesParams() {
      try {
        this.templatesLoading = true

        this.templates = await scheduleApi.getScheduleTemplatesList(this.departmentId)
        this.templates.sort(this.sortByName)

        if (!this.templates.length) {
          this.$toastr('warning', '', 'Нет шаблонов для выбора')
          return
        }

        const [lessonsBegin, bigbreaksBegin, breaksDuration, bigbreaksDuration] = await Promise.all([
          scheduleApi.getLessonsBeginList(this.departmentId),
          scheduleApi.getBigbreaksBeginList(this.departmentId),
          scheduleApi.getBreaksDurationList(this.departmentId),
          scheduleApi.getBigbreaksDurationList(this.departmentId)
        ])

        this.generateParams.lessonsBegin = lessonsBegin
        this.generateParams.bigbreaksBegin = bigbreaksBegin
        this.generateParams.breaksDuration = breaksDuration
        this.generateParams.bigbreaksDuration = bigbreaksDuration

        for (const key in this.generateParams) {
          if (!this.generateParams[key].length) {
            this.$toastr('warning', '', 'Нет значений в списках на странице "Время занятий"')
            this.templatesLoading = false
            return
          }
        }

        this.templatesLoading = false
        this.scheduleTemplatesDialog = true
      } catch (e) {
        console.log(e.status)
      }
    },

    async getCreateParams() {
      try {
        this.createLoading = true

        const [lessonsBegin] = await Promise.all([
          scheduleApi.getLessonsBeginList(this.departmentId),
        ])

        this.createParams.lessonsBegin = lessonsBegin

        if (!this.createParams.lessonsBegin.length) {
          this.$toastr('warning', '', 'Нет значений в списках на странице "Время занятий"')
          this.createLoading = false
          return
        }

        this.createLoading = false
        this.scheduleCreateDialog = true
      } catch (e) {
        console.log(e.status)
      }
    },

    closeScheduleTemplatesDialog() {
      this.scheduleTemplatesDialog = false
    },

    closeScheduleCreateDialog() {
      this.scheduleCreateDialog = false
    }
  }
}
</script>

<style scoped>
.schedule-info p {
  font-size: 15px;
  margin-bottom: 6px;
  line-height: 1;
}

.schedule-info p:last-child {
  margin-bottom: 0;
}
</style>