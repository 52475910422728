<template>
	<v-row justify="center">
		<v-dialog :value="dialog" max-width="600" persistent @input="closeDialog">
			<v-card class="dialog-card">
				<v-card-title class="headline accent--text pb-4">Создание нового расписания</v-card-title>

        <v-card-text outlined tile class="border-top">
          <v-container>
            <v-row>
              <v-col cols="12" class="px-0">
                <v-autocomplete
                  v-model="beginTime"
                  label="Время начала занятий"
                  placeholder="Выберите из списка"
                  no-data-text="Нет значений для выбора"
                  hide-details
                  :items="createParams.lessonsBegin"
                  item-text="time"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="px-6">
          <v-spacer></v-spacer>

          <v-btn
            text
            color="accent"
            @click="closeDialog"
          >
            Закрыть
          </v-btn>

          <v-btn
            text
            color="success"
            :disabled="$v.$invalid"
            @click="createSchedule"
          >
            Создать расписание
          </v-btn>
        </v-card-actions>
      </v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
	props: {
		dialog: Boolean,
    createParams: Object
	},

  data: () => ({
    beginTime: null
	}),

  validations() {
    return {
      beginTime: { required }
    }
  },

	computed: {
    ...mapGetters('department', ['departmentId']),
    ...mapGetters('cycle', ['cycleId'])
	},

	methods: {
    async createSchedule() {
      const body = {
        beginTime: this.beginTime,
      }

      this.$emit('createNewSchedule', body)
      this.closeDialog()
    },

    resetCreateParams() {
      this.beginTime = null
    },

    closeDialog() {
      this.resetCreateParams()
			this.$emit('closeDialog')
		}
	}
}
</script>