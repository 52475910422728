<template>
  <v-row justify="center">
    <v-dialog :value="dialog" max-width="950" persistent @input="closeDialog">
      <v-card class="dialog-card">
        <v-card-title class="headline accent--text pb-4">
          {{ isEdit ? 'Редактирование строки расписания' : 'Добавление строки расписания' }}
        </v-card-title>

        <v-card-text outlined tile class="border-top">
          <v-container>
            <v-row>
              <v-col cols="4" class="pb-0 pt-6 pl-0">
                <DatePicker
                  label="Дата"
                  :date="scheduleLineLocal.date"
                  :minDate="fromDate"
                  :maxDate="toDate"
                  :startMonth="fromDate"
                  :allowedDates="getAllowedDates"
                  @setDate="scheduleLineLocal.date = $event"
                />
              </v-col>

              <v-col cols="4" class="pb-0 pt-5">
                <v-text-field
                  v-model="scheduleLineLocal.startEndTimes"
                  label="Время"
                  placeholder="09:20-10:40"
                  v-mask="'##:##-##:##'"
                  :error-messages="errorMessages.startEndTimes"
                  @input="$v.scheduleLineLocal.startEndTimes.$touch()"
                  @blur="$v.scheduleLineLocal.startEndTimes.$touch()"
                />
              </v-col>

              <v-col cols="4" class="pb-0 pt-5 pr-0">
                <v-text-field
                  v-model="scheduleLineLocal.hours"
                  label="Количество часов"
                  placeholder="2"
                  type="number"
                  min="1"
                  max="8"
                  :error-messages="errorMessages.hours"
                  @input="$v.scheduleLineLocal.hours.$touch()"
                  @blur="$v.scheduleLineLocal.hours.$touch()"
                />
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-col cols="12" class="pb-0 px-0">
                <v-autocomplete
                  v-model="scheduleLineLocal.academicWork"
                  label="Вид занятий"
                  placeholder="Выберите из списка"
                  no-data-text="Нет значений для выбора"
                  return-object
                  :items="academicWorks"
                  item-text="name"
                  :error-messages="errorMessages.academicWork"
                  @input="$v.scheduleLineLocal.academicWork.$touch()"
                  @blur="$v.scheduleLineLocal.academicWork.$touch()"
                />

                <v-autocomplete
                  v-model="scheduleLineLocal.groups"
                  label="№ группы"
                  placeholder="Выберите из списка"
                  no-data-text="Нет значений для выбора. Распределите слушателей на группы."
                  multiple
                  chips
                  deletable-chips
                  :items="groupNumbers"
                  :error-messages="errorMessages.groups"
                  @change="scheduleLineLocal.groups.sort((a, b) => a - b)"
                  @input="$v.scheduleLineLocal.groups.$touch()"
                  @blur="$v.scheduleLineLocal.groups.$touch()"
                >
                  <template #selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      close-icon="mdi-close"
                      @click:close="removeGroups(data.item)"
                    >
                      {{ data.item }}
                    </v-chip>
                  </template>
                </v-autocomplete>

                <v-autocomplete
                  v-if="!noChapter"
                  v-model="scheduleLineLocal.chapter"
                  class="mb-0 pb-0"
                  label="Раздел"
                  placeholder="Выберите из списка"
                  no-data-text="Нет значений для выбора"
                  item-value="id"
                  hide-no-data
                  return-object
                  :items="chapterList.list"
                  item-text="name"
                  :error-messages="errorMessages.chapter"
                  @input="$v.scheduleLineLocal.chapter.$touch()"
                  @blur="$v.scheduleLineLocal.chapter.$touch()"
                />

                <v-text-field
                  v-if="noChapter"
                  v-model="chapterStr"
                  label="Раздел"
                  placeholder="Введите название раздела"
                  :error-messages="errorMessages.chapterStr"
                  @input="$v.chapterStr.$touch()"
                  @blur="$v.chapterStr.$touch()"
                />

                <v-checkbox
                  v-model="noChapter"
                  class="default-event-place mt-0 mb-4"
                  label="отсутствует в справочнике"
                  hide-details
                />

                <v-text-field
                  v-model="scheduleLineLocal.name"
                  label="Тема"
                  :error-messages="errorMessages.name"
                  @input="$v.scheduleLineLocal.name.$touch()"
                  @blur="$v.scheduleLineLocal.name.$touch()"
                />

                <v-autocomplete
                  v-model="scheduleLineLocal.eventPlace"
                  label="Место"
                  placeholder="Выберите из списка"
                  no-data-text="Нет значений для выбора"
                  return-object
                  :items="eventPlaces"
                  item-text="fullAddress"
                  :error-messages="errorMessages.eventPlace"
                  @input="$v.scheduleLineLocal.eventPlace.$touch()"
                  @blur="$v.scheduleLineLocal.eventPlace.$touch()"
                />

                <v-autocomplete
                  v-model="scheduleLineLocal.scheduleLects"
                  label="Преподаватель"
                  placeholder="Выберите из списка"
                  no-data-text="Нет значений для выбора"
                  multiple
                  chips
                  deletable-chips
                  return-object
                  :items="employees"
                  item-text="shortName"
                  item-value="emplRateId"
                  :error-messages="errorMessages.scheduleLects"
                  @input="$v.scheduleLineLocal.scheduleLects.$touch()"
                  @blur="$v.scheduleLineLocal.scheduleLects.$touch()"
                >
                  <template #item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.fullName }}</v-list-item-title>
                      <v-list-item-subtitle>{{ `${item.emplTitleCode} / ${item.emplWorkType}` }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>

                  <template #selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      close-icon="mdi-close"
                      @click:close="removeScheduleLects(data.item)"
                    >
                      {{ data.item.shortName }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="px-6">
          <v-spacer></v-spacer>
          <v-btn text color="accent" @click="closeDialog">Отмена</v-btn>

          <v-btn
            :disabled="$v.$invalid"
            color="success"
            :loading="createChapterLoading"
            text
            @click="saveLine"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { catalogApi, chapterApi, cycleApi, eventPlaceApi } from '@/api'
import { mapGetters } from 'vuex'
import _cloneDeep from 'lodash/cloneDeep'
import { timeIntervalStringChecker, scheduleHours, setValidateMessages } from '@/scripts/validation'
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators'
import { ScheduleLine, AcademicWork, EventPlace, Employee, ChapterList, Chapter } from '@/models'
import DatePicker from '@/components/ui/DatePicker'
import _debounce from 'lodash/debounce'

export default {
  name: 'ScheduleLine',

  components: {
    DatePicker
  },

  props: {
    dialog: Boolean,
    isEdit: Boolean,
    chaptersList: Array,
    cycleHolidays: Object,
    scheduleLine: Object
  },

  async created() {
    await this.getDictionaries()
  },

  data: () => ({
    academicWorks: [],
    eventPlaces: [],
    employees: [],
    chapters: [],
    searchChapters: '',
    chapterList: new ChapterList(),
    noChapter: false,
    chapterStr: '',
    createChapterLoading: false,

    scheduleLineLocal: new ScheduleLine()
  }),

  validations() {
    return {
      scheduleLineLocal: {
        date: { required },
        startEndTimes: { required, timeIntervalStringChecker },
        hours: { required, scheduleHours },
        academicWork: { required },
        groups: { required: requiredIf(() => this.scheduleLineLocal.isFact) }, // only for fact schedule line
        chapter: { required: requiredIf(() => !this.noChapter), maxLength: maxLength(300) },
        name: { required, maxLength: maxLength(300) },
        eventPlace: { required },
        scheduleLects: { required: requiredIf(() => ![11].includes(this.scheduleLineLocal.academicWork?.id)) }
      },

      chapterStr: { required: requiredIf(() => this.noChapter), maxLength: maxLength(300) }
    }
  },

  computed: {
    ...mapGetters('department', ['departmentId', 'department']),
    ...mapGetters('cycle', ['cycleId', 'groupCnt', 'groupCntFact', 'fromDate', 'toDate']),

    errorMessages() {
      const allErrors = {}

      Object.keys(this.$v.scheduleLineLocal.$params).forEach(key => {
        allErrors[key] = setValidateMessages(this.$v.scheduleLineLocal[key])
      })

      allErrors.chapterStr = setValidateMessages(this.$v.chapterStr)

      return allErrors
    },

    groupNumbers() {
      const totalGroups = this.scheduleLineLocal.isFact ? this.groupCntFact : this.groupCnt

      return Array.from({ length: totalGroups || 1 }, (_, i) => `${i + 1}`)
    }
  },

  methods: {
    async getDictionaries() {
      try {
        const [academicWorks, eventPlaces, employees , chapters] = await Promise.all([
          catalogApi.getAcademicWorks(),
          eventPlaceApi.getEventPlaces(this.departmentId),
          cycleApi.getEmployeesListByCycleId(this.cycleId),
          chapterApi.findAll(this.departmentId),
        ])

        this.academicWorks = academicWorks.map(el => AcademicWork.buildFromAPI(el))
        this.eventPlaces = eventPlaces.map(el => EventPlace.buildFromAPI(el))
        this.employees = employees.map(el => Employee.buildFromAPI(el))
        const sortChapters = chapters.map(el => {
          el.name?.toUpperCase();
          return el
        }).sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        })

        this.chapterList.buildFromAPI(sortChapters)
      } catch (e) {
        console.log(e.status)
      }
    },

    getAllowedDates(value) {
      return !this.cycleHolidays.datesList.includes(value)
    },

    removeGroups(item) {
      const index = this.scheduleLineLocal.groups.findIndex(el => el === item)
      index >= 0 && this.scheduleLineLocal.groups.splice(index, 1)
    },

    removeScheduleLects(item) {
      const index = this.scheduleLineLocal.scheduleLects.findIndex(el => el.emplId === item.emplId)
      index >= 0 && this.scheduleLineLocal.scheduleLects.splice(index, 1)
    },

    async saveLine() {
      // v-combobox v-model не меняет значение, пока не уберешь фокус
      //this.$refs["chapter"].blur()

      try {
        if (this.noChapter) {
          this.createChapterLoading = true
          const existingChapter =  this.chapterList.list.find(el => el.name === this.chapterStr?.trim())
          if (existingChapter) {
            this.scheduleLineLocal.chapter = existingChapter
          } else {
            const newChapter = new Chapter()
            newChapter.name = this.chapterStr
            newChapter.department = this.department
            const data = await chapterApi.add(newChapter)
            this.chapterList.addChapter(data)
            this.scheduleLineLocal.chapter = data
          }
        }

        this.$nextTick(() => {
          const line = _cloneDeep(this.scheduleLineLocal)
          const editLineEmitBody = { line, date: this.scheduleLine.date }

          this.isEdit ? this.$emit('editLine', editLineEmitBody) : this.$emit('addLine', line)
          this.closeDialog()
        })
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка сохранения раздела расписания')
      } finally {
        this.createChapterLoading = false
      }
    },

    resetModal() {
      this.$v.$reset()
      this.scheduleLineLocal = new ScheduleLine()
      this.chapterStr = ''
      this.noChapter = false
    },

    closeDialog() {
      this.resetModal()
      this.$emit('closeDialog')
    }
  },

  watch: {
    scheduleLine(val) {
      if (val) {
        this.scheduleLineLocal = _cloneDeep(val)
      }
    },

    searchChapters(val) {
      if (val?.length >= 3) {
        this.chapters = this.chaptersList.filter(el => el.toLocaleLowerCase().indexOf(val.toLocaleLowerCase()) > -1)
      } else {
        this.chapters = []
      }
    }
  }
}
</script>